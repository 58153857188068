/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import IcoPdf from "./assets/ico_pdf.png";
import IcoAgenda from "./assets/agenda.ico";
//import Logo from "./assets/DreyfusLogoSite.png";
//import LineBar from "./assets/giphy.gif";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
//import * as jsPDF from 'jspdf';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
//import { tr } from "date-fns/esm/locale";
//import gerContrato from "./gerContrato";
import copycli from "copy-to-clipboard";
//import PHE from "print-html-element";
//const nodemailer = require("nodemailer");
//import * as jsPDF from 'jspdf'
//import {saveAs} from "file-saver";
//import Embed from 'react-embed';
//import { data } from "jquery";

//var gGlobal = require('./components/globalvar.js');


var TxtSel = "";
//const clipboardy = require('clipboardy');

//import html2canvas from "html2canvas";
//import { func } from "prop-types";
//import { ReadStream } from "tty";

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol} from "mdbreact";


//const modar = require('bootstrap');


//import Form from "./Form";


const dateFormat = require("dateformat");


global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function MenuAction(){

  let mmenu = document.getElementById('body_base');
  if(mmenu.classList.contains('sidebar-open')){

    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');

  }

}

        //const deskW = window.innerWidth;



function Gerador() {

  const [ViewPdf, setViewPdf] = useState([]);

  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);

  global.isQuestionKey = '';

  if (window.innerWidth < 640){
    let mmenu = document.getElementById('body_base');
    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');
    //alert('tamanho '+window.innerWidth);  
  }

  //useEffects

  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }


  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "39873837969665057863706756756934"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');

    //eleFvdocs.submit();

    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }



  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function gerContratoLink(params) {

    window.location.href = "#/contratos-gerenciar";


  }
  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }

  // function formataCPF(cpf) {
  //   //retira os caracteres indesejados...
  //   cpf = cpf.replace(/[^\d]/g, "");

  //   //realizar a formatação...
  //   return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  // }

  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }


  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }

  const ref = null;

  // function testeModal(params) {



  function modalBase(h1, p) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>{h1}</h1><br></br><br></br>
            <p id='pmodal'><b>{p}</b></p>
            <br></br>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button
              className="btn bg-gradient-info btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div>
        );
      }
    });
  }


  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  // function TeclaEnter(params) {

  // }


  function CalcSimule(params) {

    let eleDtIni = document.getElementById('inputDtIni');
    let eleDtFim = document.getElementById('inputDtFim');

    let eleDifDias = document.getElementById('inputDifDias');
    let difDias = calculaDias(dateFormat(eleDtIni.value, "dd/mm/yyyy"), dateFormat(eleDtFim.value, "dd/mm/yyyy"));
    eleDifDias.value = difDias;
    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let M30 = Math.floor(difDias / 30);
    eleMeses30.value = M30;

    let eleTUnids = document.getElementById('inputTUnids');
    let TUnids = eleTUnids.value;

    let eleTDiasPasta = document.getElementById('inputTDiasPasta');
    let TDiasPasta = eleTDiasPasta.value;
    let elePrazo = document.getElementById('inputPrazo');
    let Prazo = (TDiasPasta * M30);
    Prazo = Math.round(Prazo);
    elePrazo.value = Prazo;

    let eleVUnit = document.getElementById('inputVUnit');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleVTotal = document.getElementById('inputVTotal');
    let VTotal = (M30 * Vunit);
    eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let elePorUnids = document.getElementById('inputPorUnids');
    let PorUnids = (VTotal / TUnids);
    elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleQParc = document.getElementById('inputQParc');
    let QParc = eleQParc.value;
    let eleVParc = document.getElementById('inputVParc');
    let VParc = (VTotal / QParc);
    eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPorUnids = document.getElementById('inputParcPorUnids');
    let ParcPorUnids = (VParc / TUnids);
    eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleTDas = document.getElementById('inputTDas');
    let TDas = (VTotal * 0.06);
    eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcDas = document.getElementById('inputParcDas');
    let ParcDas = (TDas / QParc);
    eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleTPrc = document.getElementById('inputTPrc');
    let TPrc = (VTotal * 0.20);
    eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPrc = document.getElementById('inputParcPrc');
    let ParcPrc = (TPrc / QParc);
    eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleLucro = document.getElementById('inputLucro');
    let Lucro = (VTotal - (TDas + TPrc));
    eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });


  }

  function CalcDias(params) {

    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let elePrazo = document.getElementById('inputPrazo');
    let eleTDias = document.getElementById('inputTDiasPasta');
    eleTDias.value = (elePrazo.value / eleMeses30.value);



  }

  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };

  function novoContato(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

  }

  function enviarEmail(assunto, email, txtmsg) {
    validaEmail6(document.getElementById('inputEmail6').value, 'inputEmail6')
    if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    //cl("enviar email global.inpAddFrmC.inputEmail6 > ", global.inpAddFrmC.inputEmail6)
    //cl("enviar email global.inpAddFrmC > ", global.inpAddFrmC)
    if (global.inpAddFrmC.inputEmail6) {
      setsendMsgEmail(
        {
          'assunto': document.getElementById('inputAssunto').value,
          'email': document.getElementById('inputEmail6').value,
          'txtmsg': document.getElementById('txtEmail').value,
        }

      );
      let ele = document.getElementById('panelEmail');
      ele.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.remove("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-plus");
      eleCardI.classList.add("fa-minus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = '';

      //cl("enviou email")
    }
  }

  function ViewVideo(params) {

    return(
      <>
      <div className="card card-info cardGridBggVideo" id="cardGridBggVideo" >
      <div style={{padding: '58.58% 0 0 0', position: 'relative'}}><iframe src="https://player.vimeo.com/video/460897029" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} frameBorder={0} allow="autoplay; fullscreen" allowFullScreen /></div>
      </div>
      </>
    )
    
  }



  function ViewDocs(params) {
    let linkaction = '';
    //{linkaction}

    return (
      <>
      {/* <button className="btn bg-gradient-info btn-sm" onClick={CalcDias}>Ok</button> */}
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="headCQuestion">Seus Comentários</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>

          <div className="card-body locCont" id="cardGridBggBody">
            {/* <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" /> */}
              {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
              {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
              <section className="content-header">


<div className="box box-widget">
  <div className="box-header with-border">
    <div className="user-block">
    <img className="img-circle" src="../dist/img/user3-160x160.jpg" alt="User Image" />

      <span className="username">Bloco : A Unidade: 5</span>
      <span className="description">11/09/2020 16:39</span>
    </div>
  </div>
  <div className="box-body">
    <p></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus non diam ullamcorper, sollicitudin est ut, porttitor libero. Integer dictum euismod lacus, vitae semper urna rutrum sit amet. Suspendisse lobortis non ex in commodo. Vivamus congue purus eu dui pretium malesuada id vitae lorem. Nullam venenatis a orci id eleifend. Aenean porttitor sagittis hendrerit. Phasellus ut ex enim. Etiam vestibulum turpis elit, in varius velit pellentesque quis. Mauris tincidunt neque id nibh molestie blandit. Cras mattis enim in justo faucibus, eu fermentum magna elementum.</p>
    <div className="attachment-block clearfix">
      
      <div className="attachment-pushed">
        <div className="attachment-text">
        </div>
      </div>
    </div>
  </div>
</div>


<div className="box box-widget">
  <div className="box-header with-border">
    <div className="user-block">
    <img className="img-circle" src="../dist/img/logoSupRiluk.png" alt="User Image" />

      <span className="username">Riluk Adm.</span>
      <span className="description">14/09/2020 13:55</span>
    </div>
  </div>
  <div className="box-body">
    <p></p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet nibh vitae leo rhoncus mollis et id sem. Cras sit amet eleifend turpis, sed facilisis tellus. Pellentesque interdum odio lacinia turpis elementum pulvinar. Sed quis ante nisi. Praesent efficitur ullamcorper dui, in rhoncus est suscipit vel. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra lobortis erat, id mollis odio dictum eu. Phasellus at varius nisi, in euismod mauris. Mauris sit amet ante massa. Quisque pretium vitae massa posuere rutrum. Ut porttitor dictum nisi, nec venenatis turpis. Suspendisse ac fringilla mi. Vivamus placerat nisl sit amet neque sollicitudin vestibulum. Pellentesque nec eleifend ex, at commodo quam. Nulla dapibus, nisi vitae semper vestibulum, lorem metus auctor nisi, a congue turpis tellus sit amet enim. Praesent eget nunc pellentesque, accumsan quam id, tristique lorem.</p>
    <div className="attachment-block clearfix">
      
      <div className="attachment-pushed">
        <div className="attachment-text">
        </div>
      </div>
    </div>
  </div>
</div>




            <h1 id="TxtSeltxt" className="box-title-bgg"></h1>

                <section className="content" id="divpage5">

                </section>
              </section>
            {/* </form> */}
          </div>

        </div>
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-header ui-sortable-handle card-header-vote" >
                  <h3 className="card-title" id="headCQuestion">Votação</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>

          <div className="card-body locCont" id="cardGridBggBody">
            {/* <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" /> */}
              {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
              {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
              <section className="content-header">


<div className="box box-widget">
  <div className="box-header with-border">
    <div className="user-block">
    <img className="img-circle" src="../dist/img/vote2.png" alt="User Image" />

      <span className="username">Aprova prestação de contas ?</span>
      <span className="description">aguarde liberação 20/09/2020</span>
    </div>
  </div>
  <div className="box-body">
    <p></p>
    <p>A Votação será liberada dia 20/09/2020 às 10h e se encerrará às 23:59h</p>
    <div className="attachment-block clearfix">
      
      <div className="attachment-pushed">
        <div className="attachment-text">
        </div>
      </div>
    </div>
  </div>
</div>




            <h1 id="TxtSeltxt" className="box-title-bgg"></h1>

                <section className="content" id="divpage5">

                </section>
              </section>
            {/* </form> */}
          </div>

        </div>
      
      </>

    )
  }

  function nomeAta(nome){
    nome = nome.replace('.pdf','');
    nome = nome.replace(/_/g,' ');

    return nome;
  }

  useEffect(() => {
    // Não executar inicialmente

    // if (isFirstRun8.current) {
    //   isFirstRun8.current = false;
    //   //cl("DelNCtc first3 false")
    //   return;
    // }
    let lmenu1 = document.getElementById('l01');
    lmenu1.classList.remove('active');
    let lmenu2 = document.getElementById('l02');
    lmenu2.classList.remove('active');
    let lmenu3 = document.getElementById('l03');
    lmenu3.classList.remove('active');
    let lmenu4 = document.getElementById('l04');
    lmenu4.classList.remove('active');
    let lmenu5 = document.getElementById('l05');
    lmenu5.classList.remove('active');
    let lmenu6 = document.getElementById('l06');
    lmenu6.classList.add('active');
    let lmenu7 = document.getElementById('l07');
    lmenu7.classList.remove('active');                    

    // evitar erro quando não aparecer ainda a div antes do render


    if (window.innerWidth < 800){
      let mmenu = document.getElementById('body_base');
      mmenu.classList.remove('sidebar-open');
      mmenu.classList.add('sidebar-collapse');
      //alert('tamanho '+window.innerWidth);  
    }



    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;

    //const { chavedel, propdel } = DelProp;

    return () => { ignore = true };
  }, [ViewPdf])



  function txtTxtSel(a){
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }

  function geraWordProp(chave, prop) {
    const linkurl = "http://admriluk/docx.php?apikey=398738497834758934759834758934&chave=" + chave + "&prop=" + prop;
    window.open(linkurl, '_blank');

  }

  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }


  if (global.gCond < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {ViewVideo()}
        {}
        {/* {page2()} */}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default withRouter(Gerador)