import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
//import { Link , withRouter} from "react-router-dom";
import publicIp from 'public-ip';
import { cl } from './components/functions';

const dateFormat = require("dateformat");
const TOKEN_KEY = "AD_APP_YOUEVO";

function Gerador() {

    const [FuncFooter, setFuncFooter] = useState([]);
    // const [SendComent, setSendComent] = useState([]);
    // const [ListComent, setListComent] = useState([]);

    // const isFirstRun = useRef(true);
    // const isFirstRun2 = useRef(true);
    // const isFirstRun3 = useRef(true);

    useEffect(() => {

        if (global.gADend > dateFormat(Date.now(), "dd/mm/yyyy HH:MM")) {
            //cl("não acabou");
        } else {
            localStorage.removeItem(TOKEN_KEY);
            //this.props.history.push("/login");

        }

        // const mvotar = document.getElementById('l07');
        // mvotar.style.display='';

        let ignore = false;

        return () => { ignore = true };
    }, [FuncFooter])

    function ViewFooter(params) {
        let linkaction = '';
        //{linkaction}

        return (
            <>
                <footer className="main-footer">
                    Copyright ©2019 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/">   You Evolution </a>.
                     Todos os direitos reservados.
                <div className="float-right d-none d-sm-inline-block">
                        <b>Versão</b> 2.9.5
                </div>
                </footer>


            </>

        )
    }

    return (
        <div>
            {ViewFooter()}
            {/* {page2()} */}
            {/* {page3()} */}
            {/* {page4()} */}
            {/* {page5()} */}
        </div>
    )
}
export default withRouter(Gerador)

//old
// export default class Footer extends Component {

//     render() {

//         // (async () => {
//         //     global.gIp = await publicIp.v4(); 
//         //     console.log(global.gIp);
//         //     let p_ip = document.getElementById('p_ip');
//         //     p_ip.innerHTML = "Ip: "+global.gIp;
//         //     //=> '46.5.21.123'

//         //     //console.log(await publicIp.v6());
//         //     //=> 'fe80::200:f8ff:fe21:67cf'
//         // })();

//         if (global.gADend > dateFormat(Date.now(), "dd/mm/yyyy HH:MM")){
//             //cl("não acabou");
//         } else {
//             localStorage.removeItem(TOKEN_KEY);
//             //this.props.history.push("/login");

//         }

//         return (
//             <div>
//                 <footer className="main-footer">
//                     Copyright ©2019 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/">   You Evolution </a>.
//                     Todos os direitos reservados. 
//   <div className="float-right d-none d-sm-inline-block">
//                         <b>Versão</b> 2.9.1
//   </div>
//                 </footer>


//             </div>
//         )
//     }
// }
