import React, { Component } from 'react'
import { Link , withRouter} from "react-router-dom";
//import api from "./api";
import { loginapi } from "./auth";
//import { Form, Container } from "./loginstyles";
import Logo from "./assets/RilukLogoF.png";
import axios from "axios";
import Loading from "./assets/loading.gif";
import { cl } from './components/functions';
//const isChrome = !window.chrome && !window.chrome.webstore || !window.chrome.runtime|| !window.Android;
// const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
const isChrome = true;
const dateFormat = require("dateformat");
const TOKEN_KEY = "AD_APP_YOUEVO";

// const ww = window.chrome
// console.log("ww",ww);
//console.log("isCrhome",isChrome)

//console.log(klinkbase);

// var url_string = window.location.href;
// var url = new URL(url_string);
// var key = url.searchParams.get("k");

//console.log(key); // qwerty89





class SignIn extends Component {
  state = {
    email: "",
    password: "",
    error: ""
  };

  

  handleSignIn = async e => {
    e.preventDefault();
    const btnLogin = document.getElementById("buttonLogin");
    const btnLoading = document.getElementById("buttonLoading");

    //    btnLogin = 'login100-form-btn2';
    //    var button = $('button[type=submit]', this);
    // disable and set the text
    btnLogin.hidden = true;
    btnLoading.hidden = false;
    //btnLogin.textContent= "ok"


    const { email, password } = this.state;


    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
      btnLogin.hidden = false;
      btnLoading.hidden = true;
    } else {
      try {
        var bodyFormData = new FormData();
        localStorage.setItem('linkbase', 'http://adminrl.dyndns.org:8085');
        const klinkbase = localStorage.getItem('linkbase');
        global.gLinkBase = klinkbase;
        console.log("linkbase", global.gLinkBase);
        bodyFormData.set('email', email);
        bodyFormData.set('doc', password);
        //bodyFormData.append('password', 'senha');
        const response = await axios({
          method: 'post',
          url: global.gLinkBase + '/appyou/login.php?apikey=39873837969665057863706756756934',
          //url: 'http://192.168.15.200:8085/appyou/login.php?apikey=39873837969665057863706756756934',
          // url: 'http://192.168.99.250:3333/loginusers',
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        });

        //const response = await api.post("/loginusers2", { email, password });
        //console.log(response.data);
        //console.log("login-: ", response.data );
        if (response.data) {
          loginapi(response.data);
          this.props.history.push("/");
        } else {
          this.setState({
            error:
              "E-mail ou CPF, devem ser iguais ao cadastro junto a Administradora"
          });
          btnLogin.hidden = false;
          btnLoading.hidden = true;
        }
      } catch (err) {
        console.log(err);
        if (!err.response) {
          const btnLogin = document.getElementById("buttonLogin");
          this.setState({
            error:
              ">>> Temporariamente o Link com os Servidores <<<"
          });
          btnLogin.hidden = false;
          btnLoading.hidden = true;
        } else {
          this.setState({
            error:
              "E-mail ou CPF, devem ser iguais ao cadastro junto a Administradora"
          });
          btnLogin.hidden = false;
          btnLoading.hidden = true;
        }

      }
    }
  };

  content() {
    if (isChrome) {
      return (
        <div>

          <div className="limiter">
            <div className="container-login100" style={{ backgroundImage: 'url("https://tudocondo.com.br/assets/static/assembleia-geral-ordinaria-e-extraordinaria-no-condominio.3fd7121.e3d3f49.jpg")' }}>
              <div className="wrap-login101">
                <form className="login100-form validate-form" onSubmit={this.handleSignIn}>
                  <span className="login100-form-logo">
                    <img className="imglogo" src={Logo} alt="Login" />
                    {/*<i className="zmdi zmdi-landscape" />*/}
                  </span>
                  <span className="login100-form-title p-b-46 p-t-27">
                    {/* JVF - APP */}
                  </span>
                  {/* <div className="wrap-input100 validate-input" data-validate="Enter username">
                    <input className="input100" type="email" name="email" placeholder="Email" onChange={e => this.setState({ email: e.target.value })} />
                    <span className="focus-input100" data-placeholder="" />
                  </div>
                  <div className="validate-input wrap-input100bgg " data-validate="Enter password">
                    <input className="input100" type="text" name="pass" placeholder="CPF ou CNPJ" onChange={e => this.setState({ password: e.target.value })} />
                    <span className="focus-input100" data-placeholder="" />
                  </div>
                  <div className="txt1 text-center p-t-10">
                    CPF/CNPJ (só números)
                  </div> */}
                  {/*}  <div className="contact100-form-checkbox">
                                <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                                <label className="label-checkbox100" htmlFor="ckb1">
                                    Remember me
                            </label>
      </div>*/}
                  <div className="container-login100-form-btn">
                    {/* <button type="submit" className="login100-form-btn" id="buttonLogin" name="buttonLogin">

                      <i className="fa fa-cube" aria-hidden="true"></i>
                      &nbsp;<span id="spanLogin">Entrar</span>
                    </button>*/}
                    <button type="" className="login100-form-btn" id="buttonLoading" name="buttonLoading" hidden >

                      <span id="spanLogin"><img id="imgloading" width="50px" alt="Loading.." src={Loading}></img>Aguarde</span>

                    </button> 
                  </div>

                  <div className="text-center p-t-10">
                    {this.state.error && <p className="pError">{this.state.error}</p>}

                  </div>
                  <span className="login101-form-title p-b-30 p-t-1">
                    Assembleia Digital
                  </span>
                  <span className="login102-form-title p-b-30 p-t-5">
                    <p>Para acessar, por favor utilize o link enviado para seu email!</p>
                    <p>Não esqueça de verificar as datas de início e fim.</p>
                  </span>                  
                  {/* <div className="text-center p-t-30">
                    <a className="txt1" href="/">
                      Esqueceu a senha ?
    </a>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          <div id="dropDownSelect1" />

        </div>
      )
    } else {
      return (

        <div>
          <div className="limiter">
            <div className="container-login100" style={{ backgroundImage: 'url("images/bg-01.jpg")' }}>
              <div className="wrap-login100">
                <form className="login100-form validate-form" onSubmit={this.handleSignIn}>
                  <span className="login100-form-logo">
                    <img className="imglogo" src={Logo} alt="Login" />
                    {/*<i className="zmdi zmdi-landscape" />*/}
                  </span>
                  <span className="login100-form-title p-b-75 p-t-27">
                    {/* JVF - APP */}
                  </span>
                  <div className="wrap-input100 validate-input" data-validate="Enter username">
                    <img src="https://www.google.com/chrome/static/images/chrome-logo.svg" className="login100-form-logo" alt="logo" />
                    <div className="text-center p-t-10">
                      <span className="login100-form-title p-t-14 p-t-17">
                        Utilize o Navegador Chrome do seu Computdor
                     </span>
                    </div>
                    <div className="text-center p-t-30">
                      <a
                        className="App-link"
                        href="https://www.google.com/intl/pt-BR/chrome/"
                        //target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click aqui para Instalar !
                </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      )
    }
  }
  //
  render() {
    return (
      <div>
        {this.content()}
      </div>
      /*} <Container>
         <Form onSubmit={this.handleSignIn}>
           <img src={Logo} alt="Airbnb logo" />
           {this.state.error && <p>{this.state.error}</p>}
           <input
             type="email"
             placeholder="Endereço de e-mail"
             onChange={e => this.setState({ email: e.target.value })}
           />
           <input
             type="password"
             placeholder="Senha"
             onChange={e => this.setState({ password: e.target.value })}
           />
           <button type="submit">Entrar</button>
           <hr />
{/*              <Link to="/signup">Criar conta grátis</Link>
         </Form>
     </Container>*/
    );
  }
}

export default withRouter(SignIn);