import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import './menu.css'
import { cl } from './components/functions';

const dateFormat = require("dateformat");

function AssembleiaDig() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/" className="nav-link">
                <i className="nav-icon fa fa-handshake-o" />
                <p>
                    Menu
            <i className="fas fa-angle-left right" />
                    {/* <span className="badge badge-info right">4</span> */}
                </p>
            </Link>
            <ul className="nav nav-treeview">
                <li className="nav-item" id="l01">
                    <Link to="/apresentacao" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Apresentação
                    </p>
                    </Link>
                </li>
                <li className="nav-item" id="l02">
                    <Link to="/item1" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Pauta Única
                    </p>
                    </Link>
                </li>
                {/* <li className="nav-item" id="l03">
                    <Link to="/item2" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Previsão Orçamentária
                    </p>
                    </Link>
                </li>
                <li className="nav-item" id="l04">
                    <Link to="/item3" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Vaga de Garagem
                    </p>
                    </Link>
                </li>
                <li className="nav-item" id="l05">
                    <Link to="/item4" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Pintura Condomínio
                    </p>
                    </Link>
                </li> */}
                <li className="nav-item" id="l07" style={{ display: 'none' }}>
                    <Link to="/votar" className="nav-link" >
                        <i className="fa fa-weixin nav-icon" />
                        <p>Votar
                        {/* <span className="right badge badge-danger">Novo</span> */}
                        </p>
                    </Link>
                </li>
                {/* <li className="nav-item" id="l06">
                    <Link to="/conclusao" className="nav-link">
                        <i className="fa fa-weixin nav-icon" />
                        <p>Conclusão
                    </p>
                    </Link>
                </li> */}
            </ul>
        </li>

    );
}


function Gerador() {

    const [FuncFooter, setFuncFooter] = useState([]);
    // const [SendComent, setSendComent] = useState([]);
    // const [ListComent, setListComent] = useState([]);

    // const isFirstRun = useRef(true);
    // const isFirstRun2 = useRef(true);
    // const isFirstRun3 = useRef(true);

    useEffect(() => {

        
        //cl("global.gADvotacao",global.gADvot);
        if((global.gPerfil == 1) && (global.gADvot < dateFormat(Date.now(), "dd/mm/yyyy HH:MM"))){
        const mvotar = document.getElementById('l07');
        mvotar.style.display='';
        }

        let ignore = false;

        return () => { ignore = true };
    }, [FuncFooter])


    return (
        <div >
            <aside className="main-sidebar sidebar-dark-primary elevation-4"  >
                <Link to="/" className="brand-link">
                    <img src="dist/img/logoSupRiluk.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                    <span className="brand-text font-weight-light">Riluk - Digital</span>
                </Link>
                <div className="sidebar">
                    <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-01.jpg")' }}></div>
                    <nav className="mt-2" >
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {AssembleiaDig()}
                            <li className="nav-header"></li>
                            <li className="nav-item">
                            </li>
                            <li className="nav-header"></li>
                            <li className="nav-item">
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    )
}
export default withRouter(Gerador)

// class Menu extends Component {
//     componentDidMount() {
//         //window.addEventListener('load', this.handleSignOut);

//         // const TOKEN_KEY = "KEY_APP_BGG";
//         // const token = localStorage.getItem(TOKEN_KEY);
//         // var base64Url = token.split('.')[1];
//         // var decodedValue = JSON.parse(window.atob(base64Url));
//         // console.log(decodedValue);
//         // var novoObj = decodedValue;
//         // let nome = novoObj.nome;
//         // console.log(nome);

//         // let buff = new Buffer(token, 'base64'); 
//         // if (typeof Buffer.from === "function") {
//         //     // Node 5.10+
//         //     buff = Buffer.from(buff, 'base64'); // Ta-da
//         // } else {
//         //     // older Node versions, now deprecated
//         //     buff = new Buffer(buff, 'base64'); // Ta-da
//         // } 
//         // let text = buff.toString('ascii');

//     }
//     render() {
//         //const TOKEN_KEY = "KEY_APP_BGG";
//         //const token = localStorage.getItem(TOKEN_KEY);
//         //var base64Url = token.split('.')[1];
//         //var decodedValue = JSON.parse(window.atob(base64Url));
//         //console.log(decodedValue);
//         //var novoObj = decodedValue;
//         //let nome = novoObj.nome;
//         //console.log(nome);
//         let itemmenu1;
//         let itemmenu2;
//         let itemmenu3;

//         if (global.gNome !== '') {
//             itemmenu1 = <li className="nav-item"><Link to="/visualizar-prestacao-de-contas" className="nav-link"><i className="fa fa-bar-chart nav-icon" /><p>Prestação de Contas</p></Link></li>;
//             itemmenu2 = AssembleiaDig();
//             itemmenu3 = Relatorios();
//         } else {
//             itemmenu1 = '';
//             itemmenu2 = '';
//             itemmenu3 = '';
//         }



//         return (
//             <div >
//                 <aside className="main-sidebar sidebar-dark-primary elevation-4"  >
//                     <Link to="/" className="brand-link">
//                         <img src="dist/img/logoSupRiluk.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
//                         <span className="brand-text font-weight-light">Riluk - Digital</span>
//                     </Link>
//                     <div className="sidebar">
//                         <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-01.jpg")' }}></div>
//                         <nav className="mt-2" >
//                             <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
//                                 {itemmenu2}
//                                 <li className="nav-header"></li>
//                                 <li className="nav-item">
//                                  </li>
//                                 <li className="nav-header"></li>
//                                 <li className="nav-item">
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                  </aside>
//             </div>
//         )
//     }
// }
// export default Menu