import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, HashRouter  } from "react-router-dom";
import { isAuthenticated } from "./auth";
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';

import ADapresent from './visADapresent'; //ok import TesGer from './testeTabLimpo';
import AD1 from './visAD1'; //ok import TesGer from './testeTabLimpo';
import AD2 from './visAD2'; //ok import TesGer from './testeTabLimpo';
import AD3 from './visAD3'; //ok import TesGer from './testeTabLimpo';
import AD4 from './visAD4'; //ok import TesGer from './testeTabLimpo';
import ADvotar from './visADvotar'; //ok import TesGer from './testeTabLimpo';
import ADconclusao from './visADconclusao'; //ok import TesGer from './testeTabLimpo';
import Login from './login';

//okkkkkkkkkkkkk
const Headerprops = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <>
            <Header />
        </>
    )} />
)

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => isAuthenticated() ? (
        //<Component {...props} />
        <div>
            <Headerprops />
            <Menu />
            <Component />
            <Footer />
        </div>
    ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} />
)

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <Login />
        </div>
    )} />
)

const TADapresent = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <ADapresent />
        </div>
    )} />
)
const TAD1 = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <AD1 />
        </div>
    )} />
)
const TAD2 = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <AD2 />
        </div>
    )} />
)
const TAD3 = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <AD3 />
        </div>
    )} />
)
const TAD4 = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <AD4 />
        </div>
    )} />
)
const TADvotar = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <ADvotar />
        </div>
    )} />
)
const TADconclusao = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        //<Component {...props} />
        <div>
            <ADconclusao />
        </div>
    )} />
)

const klinkbase = localStorage.getItem('linkbase'); 
global.gLinkBase = klinkbase;

const Routes = () => (
    <BrowserRouter>
        <Switch>
        {/* <TestGerRoute exact path="/ger" component={Header} /> */}
            <HashRouter>
                <PrivateRoute exact path="/" component={TADapresent} />
                <PrivateRoute exact path="/apresentacao" component={TADapresent} />
                <PrivateRoute exact path="/item1" component={TAD1} />
                <PrivateRoute exact path="/item2" component={TAD2} />
                <PrivateRoute exact path="/item3" component={TAD3} />
                <PrivateRoute exact path="/item4" component={TAD4} />
                <PrivateRoute exact path="/votar" component={TADvotar} />
                <PrivateRoute exact path="/conclusao" component={TADconclusao} />
                <LoginRoute path="/login" component={Header} />
                
            </HashRouter>
        </Switch>
    </BrowserRouter>

);

export default Routes;