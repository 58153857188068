

export const cl = (data,data2,data3) => {
    if (data && !data2 && !data3) {
    return (console.log(data))
    } else if (data && data2 && !data3) {
      return (console.log(data,data2))
    } else if (data && data2 && data3){
      return (console.log(data,data2,data3))
    }
  
}


