/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import IcoPdf from "./assets/ico_pdf.png";
import IcoAgenda from "./assets/agenda.ico";
import IcoUser from "./assets/user160x60.jpg";
import IcoResp from "./assets/user160x60.jpg";
import IcoB1 from "./assets/balao1.png";
import IcoB2 from "./assets/balao2.png";
import IcoB3 from "./assets/balao3.png";
import IcoB4 from "./assets/balao4.png";
//import Iitem4 from "./assets/item4.jpg";
import IcoM1 from "./assets/iconm1.png";

//import Logo from "./assets/DreyfusLogoSite.png";
//import LineBar from "./assets/giphy.gif";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import './ad.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
//import * as jsPDF from 'jspdf';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
//import { tr } from "date-fns/esm/locale";
//import gerContrato from "./gerContrato";
import copycli from "copy-to-clipboard";
//import PHE from "print-html-element";
//const nodemailer = require("nodemailer");
//import * as jsPDF from 'jspdf'
//import {saveAs} from "file-saver";
//import Embed from 'react-embed';
//import { data } from "jquery";

//var gGlobal = require('./components/globalvar.js');


var TxtSel = "";
//const clipboardy = require('clipboardy');

//import html2canvas from "html2canvas";
//import { func } from "prop-types";
//import { ReadStream } from "tty";

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol} from "mdbreact";


//const modar = require('bootstrap');


//import Form from "./Form";


const dateFormat = require("dateformat");
const gItem = 4;

global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function MenuAction() {

  let mmenu = document.getElementById('body_base');
  if (mmenu.classList.contains('sidebar-open')) {

    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');

  }

}

//const deskW = window.innerWidth;


function CountComent(params) {
  let bsendc = document.getElementById('ta_l02');
  let presta = document.getElementById('p_resta_dig');

  let x = 500 - bsendc.value.length;
  if (x == 0) {
    window.alert("Atingiu a quantidade máxima de caracteres digitados, por favor resumir!");
  }
  let xx = parseInt(x);
  presta.innerHTML = 'Tamanho: ' + xx + ' restantes';

  //cl("ok",bsendc.value.length);

}
function CountComent2(params) {
  let bsendc = document.getElementById('ta_l03');
  let presta = document.getElementById('p_resta_dig103');

  let x = 500 - bsendc.value.length;
  if (x == 0) {
    window.alert("Atingiu a quantidade máxima de caracteres digitados, por favor resumir!");
  }
  let xx = parseInt(x);
  presta.innerHTML = 'Tamanho: ' + xx + ' restantes';

  //cl("ok",bsendc.value.length);

}


function Gerador() {

  const [ViewPdf, setViewPdf] = useState([]);
  const [SendVoto, setSendVoto] = useState([]);
  const [ListVoto, setListVoto] = useState([]);

  const isFirstRun = useRef(true);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);


  global.isQuestionKey = '';

  if (window.innerWidth < 640) {
    let mmenu = document.getElementById('body_base');
    mmenu.classList.remove('sidebar-open');
    mmenu.classList.add('sidebar-collapse');
    //alert('tamanho '+window.innerWidth);  
  }

  //useEffects

  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }


  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "39873837969665057863706756756934"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');

    //eleFvdocs.submit();

    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }

  function bSendComent(chave, item, msg, tipo, respchave, perfil, respid) {



    let datasend = dateFormat(Date.now(), "yyyy-mm-dd HH:MM:ss");
    let bmsg = '';
    if(perfil > 1){
      bmsg = msg;
    } else {
      let bsendc = document.getElementById('ta_l02');
      bmsg = bsendc.value;
    }
    //cl("msg", msg);
    //cl("bmsg", bmsg);

    if (bmsg.length > 0) {
      //cl("msg", msg);
      let eleLoading = document.getElementById('divLoad');
      if (eleLoading) { eleLoading.style.display = ''; }

      setSendComent(
        {
          'chave': chave,
          'item': item,
          'msg': bmsg,
          'tipo': tipo,
          'respchave': respchave,
          'datasend': datasend,
          'respid' : respid

        }

      );

    }

  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function gerContratoLink(params) {

    window.location.href = "#/contratos-gerenciar";


  }
  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }

  // function formataCPF(cpf) {
  //   //retira os caracteres indesejados...
  //   cpf = cpf.replace(/[^\d]/g, "");

  //   //realizar a formatação...
  //   return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  // }

  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }


  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }

  const ref = null;

  // function testeModal(params) {



  function modalBase(h1, p) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>{h1}</h1><br></br><br></br>
            <p id='pmodal'><b>{p}</b></p>
            <br></br>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button
              className="btn bg-gradient-info btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div>
        );
      }
    });
  }


  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  // function TeclaEnter(params) {

  // }


  function CalcSimule(params) {

    let eleDtIni = document.getElementById('inputDtIni');
    let eleDtFim = document.getElementById('inputDtFim');

    let eleDifDias = document.getElementById('inputDifDias');
    let difDias = calculaDias(dateFormat(eleDtIni.value, "dd/mm/yyyy"), dateFormat(eleDtFim.value, "dd/mm/yyyy"));
    eleDifDias.value = difDias;
    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let M30 = Math.floor(difDias / 30);
    eleMeses30.value = M30;

    let eleTUnids = document.getElementById('inputTUnids');
    let TUnids = eleTUnids.value;

    let eleTDiasPasta = document.getElementById('inputTDiasPasta');
    let TDiasPasta = eleTDiasPasta.value;
    let elePrazo = document.getElementById('inputPrazo');
    let Prazo = (TDiasPasta * M30);
    Prazo = Math.round(Prazo);
    elePrazo.value = Prazo;

    let eleVUnit = document.getElementById('inputVUnit');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleVTotal = document.getElementById('inputVTotal');
    let VTotal = (M30 * Vunit);
    eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let elePorUnids = document.getElementById('inputPorUnids');
    let PorUnids = (VTotal / TUnids);
    elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleQParc = document.getElementById('inputQParc');
    let QParc = eleQParc.value;
    let eleVParc = document.getElementById('inputVParc');
    let VParc = (VTotal / QParc);
    eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPorUnids = document.getElementById('inputParcPorUnids');
    let ParcPorUnids = (VParc / TUnids);
    eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleTDas = document.getElementById('inputTDas');
    let TDas = (VTotal * 0.06);
    eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcDas = document.getElementById('inputParcDas');
    let ParcDas = (TDas / QParc);
    eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleTPrc = document.getElementById('inputTPrc');
    let TPrc = (VTotal * 0.20);
    eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPrc = document.getElementById('inputParcPrc');
    let ParcPrc = (TPrc / QParc);
    eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleLucro = document.getElementById('inputLucro');
    let Lucro = (VTotal - (TDas + TPrc));
    eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });


  }

  function CalcDias(params) {

    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let elePrazo = document.getElementById('inputPrazo');
    let eleTDias = document.getElementById('inputTDiasPasta');
    eleTDias.value = (elePrazo.value / eleMeses30.value);



  }

  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };

  function novoContato(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

  }

  function enviarEmail(assunto, email, txtmsg) {
    validaEmail6(document.getElementById('inputEmail6').value, 'inputEmail6')
    if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    //cl("enviar email global.inpAddFrmC.inputEmail6 > ", global.inpAddFrmC.inputEmail6)
    //cl("enviar email global.inpAddFrmC > ", global.inpAddFrmC)
    if (global.inpAddFrmC.inputEmail6) {
      setsendMsgEmail(
        {
          'assunto': document.getElementById('inputAssunto').value,
          'email': document.getElementById('inputEmail6').value,
          'txtmsg': document.getElementById('txtEmail').value,
        }

      );
      let ele = document.getElementById('panelEmail');
      ele.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.remove("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-plus");
      eleCardI.classList.add("fa-minus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = '';

      //cl("enviou email")
    }
  }

  function ViewVideo(params) {

    return (
      <>
        <div className="card card-info cardGridBggVideo" id="cardGridBggVideo" >
        <div style={{ position: 'relative' }}><img className="img-fluid " id="imgitem3" src={Iitem4}></img></div>
        </div>
      </>
    )

  }

  function confirmVotos(r1,r2,r3,r4) {

    let vi1 = '';
    let vi2 = '';
    let vi3 = '';
    let vi4 = '';
    let res = [];

    let eleRespItem1 = document.getElementsByName('txtItem1');
    for (var i = 0; i < eleRespItem1.length; i++) {
      if (eleRespItem1[i].checked) {
        vi1 = eleRespItem1[i].value;
      }
    }
    let eleRespItem2 = document.getElementsByName('txtItem2');
    for (var i = 0; i < eleRespItem2.length; i++) {
      if (eleRespItem2[i].checked) {
        vi2 = eleRespItem2[i].value;
      }
    }
    // let eleRespItem3 = document.getElementsByName('txtItem3');
    // for (var i = 0; i < eleRespItem3.length; i++) {
    //   if (eleRespItem3[i].checked) {
    //     vi3 = eleRespItem3[i].value;
    //   }
    // }
    // let eleRespItem4 = document.getElementsByName('txtItem4');
    // for (var i = 0; i < eleRespItem4.length; i++) {
    //   if (eleRespItem4[i].checked) {
    //     vi4 = eleRespItem4[i].value;
    //   }
    // }

    cl("res",res);

    if(vi1 == '') {alert("Responda o item 1 vazio");return;};
    if(vi2 == '') {alert("Responda o item 2 vazio");return;};
    // if(vi3 == '') {alert("Responda o item 3 vazio");return;};
    // if(vi4 == '') {alert("Responda o item 4 vazio");return;};

    r1 = vi1;
    r2 = vi2;
    // r3 = vi3;
    // r4 = vi4;
    
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja enviar os votos ?</h1><br></br><br></br>
            <p id='pmodal2'><b>Item 1) {r1}</b></p>
            <p id='pmodal2'><b>Item 2) {r2}</b></p>
            {/* <p id='pmodal2'><b>Item 3) {r3}</b></p>
            <p id='pmodal2'><b>Item 4) {r4}</b></p> */}
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                setSendVoto(
                  {
                    'r1': r1,
                    'r2': r2//,
                    //'r3': r3,
                    //'r4': r4
                  });
                onClose();
              }}
            >
              Sim, já conferi e quero enviar agora!
            </button>
          </div>
        );
      }
    });
  }


  function ViewDocs(params) {
    let linkaction = '';
    //{linkaction}

    return (
      <>
        {/* <button className="btn bg-gradient-info btn-sm" onClick={CalcDias}>Ok</button> */}
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-header ui-sortable-handle" ig="bggvot" >
            <h3 className="card-title card-title-bgg" id="headCQuestion" >Votação - Selecione todos os votos e click em enviar !</h3>
            <div className="card-tools">
            <button className="btn bg-gradient-info btn-sm " id="btnAtu" style={{ display: 'none' }} onClick={() => setListComent({'chave': global.gADkey})}>Atualizar</button>
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" />
              </button>
            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBody2">
            <section className="content-header" id="divpage5">

            </section>
          </div>
          <div className="card-body locCont" id="cardGridBggBody3">
            {/* <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" /> */}
            {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
            {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
            {/* <section className="content-header">


</section> */}
            <div className="row col-md-12">
              <label id="labelTCalc" name="labelTCalc">1)Qual orçamento aprovar ?</label>
            </div>
            <div className="row col-md-12"><br /></div>
            <div className="row col-md-12">
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem1a" name="txtItem1" className="input-dominio" value="Amais" />
                  <label id="labelAprov1" className="labelBgg-a" > Amais (R$65.326,00)</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem1n" name="txtItem1" className="input-dominio" value="Logus" />
                  <label id="labelReprov1" className="labelBgg" > Logus (R$58.272,87)</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoN" name="txtItem1" className="input-dominio" value="nenhuma" />
                  <label id="labelAnula" className="labelBgg" > Nenhuma</label>
                </div>
              </div>
            {/* //primeira assembleia
            <div className="row col-md-12">
              <label id="labelTCalc" name="labelTCalc">1)Prestação de contas : abril/2019 a março/2020</label>
            </div>
            <div className="row col-md-12"><br /></div>
            <div className="row col-md-12">
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem1a" name="txtItem1" className="input-dominio" value="Aprovo" />
                  <label id="labelAprov1" className="labelBgg-a" > Aprovo</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem1n" name="txtItem1" className="input-dominio" value="Nao aprovo" />
                  <label id="labelReprov1" className="labelBgg" > Não aprovo</label>
                </div>
              </div>
              //fim primira assembleia
              */}
              {/* <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoN" name="txtItem1" className="input-dominio" value="anula" />
                  <label id="labelAnula" className="labelBgg" > Anular</label>
                </div>
              </div> */}
            </div>
<div className="bgg-espaco"><br /></div>

<div className="row col-md-12">
              <label id="labelTCalc" name="labelTCalc">2)Qual opção de Rateo ? </label>
            </div>
            <div className="row col-md-12"><br /></div>
            <div className="row col-md-12">
              <div className="col-sm-4 col-md-3">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem2a" name="txtItem2" className="input-dominio" value="12x" />
                  <label id="labelAprov2" className="labelBgg-a" > 12x</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-3">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem2b" name="txtItem2" className="input-dominio" value="18x" />
                  <label id="labelAprov2" className="labelBgg-a" > 18x</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-3">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem2c" name="txtItem2" className="input-dominio" value="24x" />
                  <label id="labelAprov2" className="labelBgg-a" > 24x</label>
                </div>
              </div>                            
              <div className="col-sm-4 col-md-3">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem2d" name="txtItem2" className="input-dominio" value="nao ratear" />
                  <label id="labelReprov2" className="labelBgg" > Não Ratear</label>
                </div>
              </div>
              {/* <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoN" name="txtItem2" className="input-dominio" value="anula" />
                  <label id="labelAnula" className="labelBgg" > Anular</label>
                </div>
              </div> */}
            </div>
{/* <div className="bgg-espaco"><br /></div> */}

{/* <div className="row col-md-12">
              <label id="labelTCalc" name="labelTCalc">3)Sorteio de vagas prorrogado para Fevereiro 2021</label>
            </div>
            <div className="row col-md-12"><br /></div>
            <div className="row col-md-12">
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem3a" name="txtItem3" className="input-dominio" value="Aprovo" />
                  <label id="labelAprov3" className="labelBgg-a" > Aprovo</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtItem3n" name="txtItem3" className="input-dominio" value="Nao aprovo" />
                  <label id="labelReprov3" className="labelBgg" > Não aprovo</label>
                </div>
              </div> */}
              {/* <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoN" name="txtItem3" className="input-dominio" value="anula" />
                  <label id="labelAnula" className="labelBgg" > Anular</label>
                </div>
              </div> */}
            {/* </div> */}
{/* <div className="bgg-espaco"><br /></div> */}
{/* 
            <div className="row col-md-12">
              <label id="labelTCalc" name="labelTCalc">4) Informativo referente a Pintura do condomínio</label>
            </div>
            <div className="row col-md-12"><br /></div> */}
            {/* <div className="row col-md-12">
              <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoA" name="txtItem4" className="input-dominio" defaultChecked value="Ciente" />
                  <label id="labelAprov4" className="labelBgg-a" > Ciente</label>
                </div>
              </div> */}
              {/* <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoR" name="txtItem4" className="input-dominio" value="Nao aprovo" />
                  <label id="labelReprov4" className="labelBgg" > Não aprovo</label>
                </div>
              </div> */}
              {/* <div className="col-sm-4 col-md-4">
                <div className="form-group-bgg ">
                  <input type="radio" id="txtTipoN" name="txtItem4" className="input-dominio" value="anula" />
                  <label id="labelAnula" className="labelBgg" > Anular</label>
                </div>
              </div> */}
            {/* </div> */}
            <div className="btenvvot">
            <button className="btn bg-gradient-info btn-sm float-center btnbgg" id="btnEnvVotos" onClick={()=>confirmVotos()} style={{ display: 'none'}}>Enviar Votos</button>  
            </div>
          <div className="row col-md-12">
            <br /><span>** O(s) voto(s) referente(s) a sua chave de acesso, serão validados conforme a convenção condomninial! </span>
          </div>




          </div>

        </div>
        {/* <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-superpowers fa-spin fa-4x fa-fw"></i></div>
          <div className="card-header ui-sortable-handle card-header-vote" >
            <h3 className="card-title" id="headCQuestion">Votação</h3>
            <div className="card-tools">
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" />
              </button>
            </div>
          </div>

          <div className="card-body locCont" id="cardGridBggBody">
            <section className="content-header">


              <div className="box box-widget">
                <div className="box-header with-border">
                  <div className="user-block">
                    <img className="img-circle" src="../dist/img/vote2.png" alt="User Image" />

                    <span className="username">Aprova prestação de contas ?</span>
                    <span className="description">aguarde liberação 20/09/2020</span>
                  </div>
                </div>
                <div className="box-body">
                  <p></p>
                  <p>A Votação será liberada dia 20/09/2020 às 10h e se encerrará às 23:59h</p>
                  <div className="attachment-block clearfix">

                    <div className="attachment-pushed">
                      <div className="attachment-text">
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              <h1 id="TxtSeltxt" className="box-title-bgg"></h1>

            </section>

          </div>

        </div> */}

      </>

    )
  }

  function nomeAta(nome) {
    nome = nome.replace('.pdf', '');
    nome = nome.replace(/_/g, ' ');

    return nome;
  }

  //ListVoto
  useEffect(() => {

    //inicio axios
    const { chave, item, msg, tipo, respchave, datasend } = ListVoto;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '3987383796966505786370675675693455');
      bodyFormData.set('chave', global.gADkey);
      
      //cl("key", global.gADkey);
      //bodyFormData.set('item', gItem);
      //bodyFormData.set('perfil', global.gPerfil);
      // bodyFormData.set('msg', msg);
      // bodyFormData.set('tipo', tipo);
      // bodyFormData.set('respchave', respchave);
      // bodyFormData.set('datasend', datasend);
      // bodyFormData.set('bl', global.gBl);
      // bodyFormData.set('und', global.gUnid);
      // bodyFormData.set('cond', global.gCond);


      //cl("bodyFormData",chave);

      const response8 = await axios({
        method: 'post',

        url: 'http://assembleia.riluk.com.br/api/list_votos_ad.php',
        //url: 'http://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response8) {
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
        //cl("resp data 8", response8['data']);


        const respprop = response8['data'];
        var element = document.getElementById("divpage5");
        //limpar div
        element.innerHTML = "";

        if (respprop.length > 0) {

          document.getElementById('btnEnvVotos').style.display = 'none';

          const txtcoment = document.getElementById('headCQuestion');
          txtcoment.innerHTML="Sua unidadade já votou !";
          

          if(respprop[0]['r1'] == "Amais"){
            //cl("resprop",respprop[0]['r1']);
            document.getElementById('txtItem1a').checked = 'true';
            document.getElementById('txtItem1n').remove();
            document.getElementById('txtTipoN').remove();
          } 
          if(respprop[0]['r1'] == "Logus"){
            //cl("resprop",respprop[0]['r1']);
            document.getElementById('txtItem1n').checked = 'true';
            document.getElementById('txtItem1a').remove();
            document.getElementById('txtTipoN').remove();
          } 
          if(respprop[0]['r1'] == "nenhuma"){
            //cl("resprop",respprop[0]['r1']);
            document.getElementById('txtTipoN').checked = 'true';
            document.getElementById('txtItem1a').remove();
            document.getElementById('txtItem1n').remove();
          } 
          

          if(respprop[0]['r2'] == "12x"){
            //cl("resprop",respprop[0]['r2']);
            document.getElementById('txtItem2a').checked = 'true';
            document.getElementById('txtItem2b').remove();
            document.getElementById('txtItem2c').remove();
            document.getElementById('txtItem2d').remove();
          }
          if(respprop[0]['r2'] == "18x"){
            //cl("resprop",respprop[0]['r2']);
            document.getElementById('txtItem2b').checked = 'true';
            document.getElementById('txtItem2a').remove();
            document.getElementById('txtItem2c').remove();
            document.getElementById('txtItem2d').remove();
          }
          if(respprop[0]['r2'] == "24x"){
            //cl("resprop",respprop[0]['r2']);
            document.getElementById('txtItem2c').checked = 'true';
            document.getElementById('txtItem2b').remove();
            document.getElementById('txtItem2a').remove();
            document.getElementById('txtItem2d').remove();
          }
          if(respprop[0]['r2'] == "nao ratear"){
            //cl("resprop",respprop[0]['r2']);
            document.getElementById('txtItem2d').checked = 'true';
            document.getElementById('txtItem2b').remove();
            document.getElementById('txtItem2c').remove();
            document.getElementById('txtItem2a').remove();
          }                    
          // if(respprop[0]['r3'] == "Aprovo"){
          //   //cl("resprop",respprop[0]['r3']);
          //   document.getElementById('txtItem3a').checked = 'true';
          //   document.getElementById('labelReprov3').remove();
          //   document.getElementById('txtItem3n').remove();
          // } else {
          //   document.getElementById('txtItem3n').checked = 'true';
          //   document.getElementById('labelAprov3').remove();
          //   document.getElementById('txtItem3a').remove();

          // }
          
          





          let eleRespItem1 = document.getElementsByName('txtItem1');
          let eleRespItem2 = document.getElementsByName('txtItem2');
          let eleRespItem3 = document.getElementsByName('txtItem3');




          // const listItems = respprop.sort();
          // listItems.forEach(function (item) {

          //   const span1 = document.createElement("span");
          //   span1.setAttribute('class', 'username');
          //   let txtsp1 = '';
          //   if (item["tipo"] == 0) {
          //     txtsp1 = "Bloco: " + item["bl"] + " / Unid: " + item["unid"];
          //   } else {
          //     txtsp1 = item["nomecond"];
          //   }
          //   const nodesp1 = document.createTextNode(txtsp1);
          //   span1.appendChild(nodesp1);

          //   const span2 = document.createElement("span");
          //   span2.setAttribute('class', 'description');
          //   const txtsp2 = dateFormat(item["datasend"], "dd/mm/yyyy HH:MM");
          //   const nodesp2 = document.createTextNode(txtsp2);
          //   span2.appendChild(nodesp2);


          //   const oImg = document.createElement("IMG");
          //   oImg.setAttribute('src', IcoUser);
          //   oImg.setAttribute('alt', 'na');
          //   oImg.setAttribute('height', '40px');
          //   oImg.setAttribute('width', '40px');
          //   oImg.setAttribute('class', 'img-circle');

          //   const oImgb1 = document.createElement("IMG");
          //   oImgb1.setAttribute('src', IcoB4);
          //   oImgb1.setAttribute('alt', 'na');
          //   oImgb1.setAttribute('height', '40px');
          //   oImgb1.setAttribute('width', '40px');
          //   oImgb1.setAttribute('class', 'img-circle');

          //   const oImgb2 = document.createElement("IMG");
          //   oImgb2.setAttribute('src', IcoB3);
          //   oImgb2.setAttribute('alt', 'na');
          //   oImgb2.setAttribute('height', '40px');
          //   oImgb2.setAttribute('width', '10px');
          //   oImgb2.setAttribute('class', 'img-circle');

          //   const divub = document.createElement("div");
          //   divub.setAttribute('class', 'user-block ');
          //   if (item["tipo"] == 1) {
          //     divub.appendChild(oImgb1);
          //   } else {
          //     divub.appendChild(oImgb2);
          //   }
          //   divub.appendChild(span1);
          //   divub.appendChild(span2);

          //   const ff = e => respComent(item["id"], item["chave"], item["descmsg"]);
          //   const bt1 = document.createElement('button');
          //   bt1.setAttribute('class', 'btn bg-gradient-info btn-sm float-right');


          //   const txtbt1 = "Resp";
          //   const nodebt1 = document.createTextNode(txtbt1);
          //   bt1.appendChild(nodebt1);


          //   const divbh = document.createElement("div");
          //   divbh.setAttribute('class', 'box-header with-border');
          //   if (item["tipo"] == 1) {
          //     divbh.setAttribute('class', 'box-header with-border box-widget-resp');
          //   }
          //   if ((global.gPerfil == 2) && ((item["tipo"] == 0))) {
          //     divbh.appendChild(bt1);
          //   }
          //   divbh.appendChild(divub);

          //   const p1 = document.createElement('p');
          //   const p2 = document.createElement('p');
          //   const txtp2 = item["descmsg"];
          //   const nodep2 = document.createTextNode(txtp2);
          //   p2.appendChild(nodep2);

          //   const divabc = document.createElement('div');
          //   divabc.setAttribute('class', 'attachment-block clearfix');


          //   const divbb = document.createElement("div");
          //   divbb.setAttribute('class', 'box-body');
          //   if (item["tipo"] == 1) {
          //     divbb.setAttribute('class', 'box-body box-widget-resp');
          //   } else {
          //     divbb.setAttribute('class', 'box-body');
          //   }
          //   divbb.appendChild(p1);
          //   divbb.appendChild(p2);
          //   divbb.appendChild(divabc);

          //   const divbbw = document.createElement('div');
          //   divbbw.setAttribute('class', 'box box-widget');
          //   divbbw.appendChild(divbh);
          //   divbbw.appendChild(divbb);

          //   const sect1 = document.createElement('section');
          //   sect1.setAttribute('class', 'content-header');
          //   //sect1.appendChild(divbbw);






          //   element.appendChild(divbbw);

          //   bt1.addEventListener('click', ff);
 //         });
        } else {

          document.getElementById('btnEnvVotos').style.display = '';
          
        }
        // if (global.gPerfil == 2){
        //   const t102 = document.getElementById('cardGridBggBody3');
        //   if(t102){t102.remove();}
        //   const txtcoment = document.getElementById('headCQuestion');
        //   txtcoment.innerHTML="Lista de Comentários";
        //   const dbtnatu = document.getElementById('btnAtu');
        //   dbtnatu.style.display = '';
        //   //t102.style.display = 'none';
        // }
        // if (global.gPerfil == 0){
        //   const t102 = document.getElementById('cardGridBgg');
        //   if(t102){t102.remove();}
        //   //t102.style.display = 'none';
        // }


        
        //cl("listcoment", response8['data']);
      };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();


    //fim axios

    //cl("SendComent");
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = 'none'; }
    let ignore = false;


    return () => { ignore = true };
  }, [ListVoto])


  //sendvoto
  useEffect(() => {

    if (isFirstRun3.current) {
      isFirstRun3.current = false;
      return;
    }

    //inicio axios
    const { r1,r2,r3,r4 } = SendVoto;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '3987383796966505786370675675693455');
      bodyFormData.set('chave', global.gADkey);
      bodyFormData.set('r1', r1);
      bodyFormData.set('r2', r2);
      bodyFormData.set('r3', r3);
      bodyFormData.set('r4', r4);
      bodyFormData.set('bl', global.gBl);
      bodyFormData.set('und', global.gUnid);
      bodyFormData.set('cond', global.gCond);      
      bodyFormData.set('datavoto', dateFormat(Date.now(), "yyyy-mm-dd HH:MM:ss"));      

      //cl("bodyFormData",chave);

      const response8 = await axios({
        method: 'post',

        url: 'http://assembleia.riluk.com.br/api/add_voto_ad.php',
        //url: 'http://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response8) {

        if (response8['data'] == 'GRAVADA') {
          // if(global.gPerfil == 1){
          // let bsendc = document.getElementById('ta_l02');
          // bsendc.value = '';
          // }

          // setListComent(
          //   {
          //     'chave': global.gADkey

          //   }

          // );

        }

        if (response8['data'] == 'EXISTENTE') {

          // let bsendc = document.getElementById('ta_l02');
          // bsendc.value = '';

        }

        setListVoto(
          {
            'chave': global.gADkey
 
          }
  
        );

      };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();


    //fim axios

    //cl("SendComent");
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = 'none'; }
    let ignore = false;


    return () => { ignore = true };
  }, [SendVoto])


  useEffect(() => {
    // Não executar inicialmente

    // if (isFirstRun8.current) {
    //   isFirstRun8.current = false;
    //   //cl("DelNCtc first3 false")
    //   return;
    // }
    let lmenu1 = document.getElementById('l01');
    lmenu1.classList.remove('active');
    let lmenu2 = document.getElementById('l02');
    lmenu2.classList.remove('active');
    // let lmenu3 = document.getElementById('l03');
    // lmenu3.classList.remove('active');
    // let lmenu4 = document.getElementById('l04');
    // lmenu4.classList.remove('active');
    // let lmenu5 = document.getElementById('l05');
    // lmenu5.classList.remove('active');
    // let lmenu6 = document.getElementById('l06');
    // lmenu6.classList.remove('active');
    let lmenu7 = document.getElementById('l07');
    lmenu7.classList.add('active');
    

    // evitar erro quando não aparecer ainda a div antes do render


    if (window.innerWidth < 800) {
      let mmenu = document.getElementById('body_base');
      mmenu.classList.remove('sidebar-open');
      mmenu.classList.add('sidebar-collapse');
      //alert('tamanho '+window.innerWidth);  
    }

    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = 'none'; }
    let ignore = false;

    //const { chavedel, propdel } = DelProp;

    return () => { ignore = true };
  }, [ViewPdf])



  function txtTxtSel(a) {
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }

  function geraWordProp(chave, prop) {
    const linkurl = "http://admriluk/docx.php?apikey=398738497834758934759834758934&chave=" + chave + "&prop=" + prop;
    window.open(linkurl, '_blank');

  }

  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }

  function respComent(id, chave, msg) {
    //cl("id", id);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Responder Comentário</h1><br></br>
            <p id='pmodal'>{msg}</p>
            <br></br>
            <div className="box box-widget bggbox-widget">
              <div className="box-body">
                <textarea className="form-control input-sm tabgg" onKeyUp={e => CountComent2()} maxLength={500} id="ta_l03" defaultValue={""} placeholder={"para comentar, digite aqui..."} />
                <p className="float-left" id="p_resta_dig103">Tamanho: 500 restantes</p>

              </div>
            </div>
            <button type="button" className="btn bg-gradient-info btn-sm float-right" id="btnComent" name="btnComent" onClick={() => {
              const ta103txt = document.getElementById('ta_l03').value;
              if (ta103txt.length > 0) {
                //chave, item, msg, tipo, respchave, perfil, txtta
                bSendComent(global.gADkey, gItem, ta103txt, '1', chave, global.gPerfil, id);
                onClose();
              }

            }} >Enviar comentário</button>
            <br></br><br></br><button className="btn bg-gradient-info btn-sm" onClick={onClose}>cancelar</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }


  if (global.gCond < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {ViewDocs()}
        {/* {page2()} */}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default withRouter(Gerador)