import React from 'react'
import Routes from "./routes"
//var cors = require('cors')
// import Header from './Header';
// import Menu from './Menu';
// import Footer from './Footer';
// import Content from './Content';

 
const App = () => <Routes />;
//App.use(cors())

export default App;


// export default class App extends Component {
//   render() {
//     return (
//       <div>
//       <Header />
//       <Menu/>
//       <Content/>
//       <Footer/>
//       </div>
//     )
//   }
// }
